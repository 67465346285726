@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;